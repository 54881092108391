<template>
  <div class="root">
  <div class="login">
    <el-card>
      <el-row>
        <el-col :span="6">
          <div class="icon-image">
            <el-image :src="iconImage"></el-image>
          </div>
        </el-col>
      </el-row>
      <div class="title">{{$t('Login_Title')}}</div>
      <!--替換語系
      <h2>Login</h2>-->
      <div class="login_font">{{$t('Login')}}</div>
      <el-form style="display: inline-block;"
          class="login-form"
          :model="model"
          :rules="rules"
          ref="form"
          @submit.native.prevent="login"
      >
        <el-form-item prop="username" style="flex: auto">
        <!--替換語系
          <el-input v-model="model.username" placeholder="Username" prefix-icon="fas fa-user"></el-input> -->
          <el-input v-model="model.username" v-bind:placeholder="$t('Username')" prefix-icon="el-icon-user-solid"></el-input>
        </el-form-item>
        <el-form-item prop="password">
        <!--替換語系
          <el-input
              v-model="model.password"
              placeholder="Password"
              type="password"
              prefix-icon="fas fa-lock"
          ></el-input>-->
          <el-input
              v-model="model.password"
              v-bind:placeholder="$t('Password')"
              type="password"
              prefix-icon="el-icon-lock"
          ></el-input>
        </el-form-item>
        <!--Captcha 驗證碼 -->
        <el-form-item>
          <el-col :span="12">
            <el-form-item prop="captcha">
              <el-input type="test" v-model="model.captcha" auto-complete="off" placeholder="驗證碼"
                        style="width: 100%;">
              </el-input>
            </el-form-item>
          </el-col>
          <el-col class="line" :span="1">&nbsp;</el-col>

          <el-col :span="11">
            <el-tooltip class="item" effect="light" content="點擊更新驗證碼" placement="bottom">
              <el-form-item>
                <img v-if="show" style="width: 100%;" class="image" :src=mybaseUrl @click="refreshCaptcha">
              </el-form-item>
            </el-tooltip>
            <!--            <el-button @click.native="refreshCaptcha" icon="el-icon-refresh"></el-button>-->
          </el-col>

        </el-form-item>
        <el-form-item>
          <el-button
              :loading="loading"
              class="login-button"
              type="primary"
              native-type="submit"
              block
          ><!--替換語系
              Login-->
            {{$t('Login')}}
          </el-button>
        </el-form-item>
<!--        <a class="forgot-password" href="https://oxfordinformatics.com/">Forgot password ?</a>-->
      </el-form>
    </el-card>
  </div>
  </div>
</template>

<script>

import * as main from "@/utils/main";
import { refreshToken, stopRefreshToken, justDoRefreshToken } from "@/utils/auth";
import router from "@/router";
import Vue from "vue";
const authUrl = main.authUrl();
const baseUrl = main.baseUrl();
export default {
  name: "login",
  data() {
    return {
      mybaseUrl: "",
      iconImage:require('@/assets/icons/img/logo_cht.png'),
      show:true,
      // imageUrl:"",
      validCredentials: {
        username: "lightscope",
        password: "lightscope",
        captcha:  "lightscope",
      },
      model: {
        username: "",
        password: "",
        captcha: "",
        src: ""
      },
      loading: false,
      rules: {
        username: [
          {
            required: true,

          //message: "Username is required",//替換語系
            message: this.$t('Username')+this.$t('Required'),
            trigger: "blur"
          },
          {
            min: 4,
          //message: "Username length should be at least 5 characters",//替換語系
            message: this.$t('Username_length',{n:'5'}),
            trigger: "blur"
          }
        ],
        password: [
        //{ required: true, message: "Password is required", trigger: "blur" },//替換語系
          { required: true, message: this.$t('Password')+this.$t('Required'), trigger: "blur" },
          {
            min: 5,
          //message: "Password length should be at least 5 characters",//替換語系
            message: this.$t('Password_length',{n:'5'}),
            trigger: "blur"
          }

        ]
      }
    };
  },
  created(){
    console.log("login created");
    this.mybaseUrl = authUrl+"captcha";
    this.refreshCaptcha();
    localStorage.clear() // 將 localstorage 所有屬性移除
    sessionStorage.clear() // 將 session storage 所有屬性移除
  },
  methods: {
    refreshCaptcha(){
      console.log("refreshCaptcha");
      this.show = false
      this.$nextTick(() => {
        this.show = true
      })
    },
    simulateLogin() {
      return new Promise(resolve => {
        setTimeout(resolve, 800);
      });
    },
    async login() {
      try{
        console.log('do loginByAuth...');
        //this.refreshCaptcha();

        console.log(this.model.username);

        if(this.model.username ==='' || this.model.password === '') {
        //return alert('請將資料填寫完整!');//替換語系
          return alert(this.$t('Complete_info'));

        }

        this.isAuthLoading = true;
        //const response = await loginByAuth(this.model.username, this.model.password, authUrl);
        await this.axios.post(authUrl,{
              username: this.model.username, password: this.model.password, captcha: this.model.captcha},{ withCredentials: true }
        ).then(response => {
          if (response != null) {
            this.result = this.$t('Logged_in');
            this.$store.commit('setIsAuthenticated',true);
            sessionStorage.setItem('csrf_token', response.data.data);
            //定時更新JWT
            justDoRefreshToken();
            // 取得目前登入使用者
            Vue.axios.get('/api/v1/common/currentAccount').then(res => {
              console.log("getCurrentAccount",res)
              this.$store.commit("setCurrentAccount",res.data);
              this.$store.dispatch("getStationByCurrentAccount") // 取得營運商所有充電站資訊
              this.$store.dispatch("getPointByCurrentAccount") // 取得營運商所有充電樁資訊
              this.$store.dispatch("getConnectorByCurrentAccount") // 取得營運商所有充電槍資訊
              this.$store.dispatch("getAllMenu") //取得resource/config/menu.json所有木路資訊

              router.replace({name: 'Main'});
              return true
            }).catch( error => {
              console.err(error)
              return false
            })
          }

        }).catch(err => {
          console.log(err)
        })

        this.isAuthLoading = false;

      }catch(error)
      {
        console.log(error);
      }

    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
@media (max-width: 600px) {
  .login-form {
    width: 250px !important;
  }
  .title{
    font-size: 1.6rem;
    font-weight: bold;
    color: #1d5477;
    margin-top: 0px;
  }

  .login_font {
    font-size: 1.3rem;
    font-weight: bold;
    color: #1d5477;
    margin-top: 0px;
  }
}

@media (max-width: 500px) {
  .login-form {
    width: 200px !important;
  }
  .title{
    font-size: 1.5rem;
    font-weight: bold;
    color: #1d5477;
    margin-top: 0px;
  }
  .login_font {
    font-size: 1.2rem;
    font-weight: bold;
    color: #1d5477;
    margin-top: 0px;
  }
}

@media (max-width: 400px) {
  .login-form {
    width: 150px !important;
  }
  .title{
    font-size: 1.2rem;
    font-weight: bold;
    color: #1d5477;
    margin-top: 0px;
  }
  .login_font {
    font-size: 1.0rem;
    font-weight: bold;
    color: #1d5477;
    margin-top: 0px;
  }

  .icon-image {
    width: 250px;
    display: flex;
    justify-content: center;
  }
}

.root {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;



}
.login {
  /*flex: 1;*/
  /*display: flex;*/
  /*justify-content: center;*/
  /*align-items: center;*/
  width: 60%;
  height: 70%;
  margin-top: 20px;


  border-radius: 5px;
  display: flex;
  justify-content: center;
}

.login-button {
  width: 100%;
  margin-top: 40px;
  font-size: 1.2rem;
}
.login-form {
  width: 350px;
}

.forgot-password {
  margin-top: 10px;
}
</style>
<style lang="scss">
$teal: rgb(65, 105, 225);
.el-button--primary {
  background: $teal;
  border-color: $teal;

  &:hover,
  &.active,
  &:focus {
    background: lighten($teal, 7);
    border-color: lighten($teal, 7);
  }
}
.login .el-input__inner:hover {
  border-color: $teal;
}
.login .el-input__inner::placeholder{
  color: #778899;
}
.login .el-input__prefix {
  width: 50px;
  font-size: 20px;
  color: #1d5477;
  background: rgb(220, 220, 220);
  left: 0;
  height: calc(100% - 2px);
  left: 1px;
  top: 1px;
  border-radius: 3px;
  .el-input__icon {
    width: 30px;
  }
}
.login .el-input input {
  padding-left: 60px;
  font-size: 16px;
}
.login .el-input input[type="test"] {
  padding-left: 10px;
  font-size: 16px;
}
.login .el-card {


}
h2 {
  font-family: "Open Sans";
  letter-spacing: 1px;
  font-family: Roboto, sans-serif;
  padding-bottom: 20px;
}
a {
  color: $teal;
  text-decoration: none;
  &:hover,
  &:active,
  &:focus {
    color: lighten($teal, 7);
  }
}
.login .el-card {
  width: 840px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 0;
  padding-bottom: 30px;
  background: url("~@/assets/icons/img/background_img4.png");
  background-size: 100% 75%;
  background-position: bottom;
}
.title{
  font-size: 2.1rem;
  font-weight: bold;
  color: #1d5477;
  margin-top: 0px;
}

.login_font {
  font-size: 1.4rem;
  font-weight: bold;
  color: #1d5477;
  margin-top: 0px;
}

.icon-image {
  width: 300px;
  display: flex;
  justify-content: center;
}

/*Captcha*/
.image {
  width: 100px;
  height: 38px;
  margin: 2px;
  cursor: pointer;
  transition: filter 0.3s ease-in;
}
</style>
